import React, { useState, useEffect} from 'react';
import { MdDownloadForOffline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { client, urlFor} from '../client';
import MasonryLayout from './MasonryLayout';
import Spinner from './Spinner';
import { pinDetailMorePinQuery, pinDetailQuery } from '../utils/data';

const PinDetail = ( { user } ) => {
  const [pins, setPins] = useState(null);
  const [pinDetail, setPinDetail] = useState();
  const [comment, setComment] = useState('');
  const [addingComment, setAddingComment] = useState(false);
  const { pinId } = useParams();

  const fetchPinDetails = () => {
    const query = pinDetailQuery(pinId);
    if (query) {
      client.fetch(`${query}`).then((data) => {
        setPinDetail(data[0]);
        if (data[0]) {
          const query1 = pinDetailMorePinQuery(data[0]);
          client.fetch(query1).then((res) => {
            setPins(res);
          });
        }
      });
    }
  };

    useEffect(() => {
      fetchPinDetails();
    }, [pinId]);


  const addComment = () => {
      if (comment) {
        setAddingComment(true);

        client.patch(pinId).setIfMissing({comments: []}).insert('after', 'comments[-1]', [
          {
            comment,
            _key: v4(),
            postedBy: {
              _type: 'postedBy',
              _ref: user._id
            }
          }
        ]).commit().then(() => {
          fetchPinDetails();
          setComment('');
          setAddingComment(false);
        });
      }
  };
  

  if (!pinDetail) {
    return <Spinner message="Chargement pin" />
  }

  return (
    <>
    <div className='flex xl-flex-row flex-col m-auto bg-white' style={{maxWidth: '1500px', borderRadius: '32px'}}>
        <div className='flex justify-center items-center md:items-start flex-initial'>
            <img alt='user-post' className='rounded-t-3xl rounded-b-lg' src={pinDetail?.image && urlFor(pinDetail.image).url()} />
        </div>
        <div className='w-full p-5 flex-1 xl:min-w-620'>
            <div className='flex items-center justify-between'>
                <div className='flex gap-2 items-center'>
                  <a className='bg-white w-9 h-9 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none' href={`${pinDetail?.image?.asset?.url}?dl=`} download onClick={(e) => e.stopPropagation()}>
                      <MdDownloadForOffline />
                  </a>
                </div>
                <a href={pinDetail?.destination} targe="_blank">
                  {pinDetail?.destination}
                </a>
            </div>
            <div >
                <h1 className='text-4xl font-bold break-words mt-3'>
                  {pinDetail.title}
                </h1>
                <p className='mt-2'>
                  {pinDetail.about}
                </p>
            </div>
            <Link to={`user-profile/${pinDetail.postedBy?._id}`} className="flex gap-2 mt-5 items-center bg-white rounded-lg">
              <img className='w-8 h-8 rounded-full object-cover' src={pinDetail.postedBy?.image} alt="user-profile" />
              <p className='font-semibold capitalize'>{pinDetail.postedBy?.userName}</p>
            </Link>
          <h2 className='mt-5 text-2xl'>Commentaires</h2>
          <div className='max-h-370 overflow-y-auto'>
            {pinDetail?.comments?.map((comment, i) => {
              return (
                <div key={i} className='flex gap-2 mt-5 items-center bg-white rounded-lg'>
                  <img src={comment.postedBy.image} alt="user-profile" className='w-10 h-10 rounded-full cursor-pointer' />
                  <div className='flex flex-col'>
                    <p className='font-bold'>{comment.postedBy.userName}</p>
                    <p>{comment.comment}</p>
                  </div>
              </div>
              );
            })}
          </div>
          <div className='flex flex-wrap mt-6 gap-3'>
            <Link to={`user-profile/${pinDetail.postedBy?._id}`} className="flex gap-2 mt-5 items-center bg-white rounded-lg">
              <img className='w-10 h-10 rounded-full cursor-pointer' src={pinDetail.postedBy?.image} alt="user-profile" />
            </Link>
            <input onChange={(e) => setComment(e.target.value)} value={comment} type="text" placeholder="Ajouter un commentaire" className='flex-1 bprder-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300' />
            <button type="button" className='bg-red-500 text-white rounded-full px-6 py-2 font-semibold' onClick={addComment}>
              {addingComment ? 'Ajout en cours...' : 'Ajouter'}
            </button>
          </div>
        </div>
    </div>
    {pins?.length > 0 ? (
      <>
        <h2 className='text-center font-bold text-2x mt-8 mb-4'>
          Plus comme ceci 
        </h2>
        <MasonryLayout pins={pins} />
      </>
    ) : (
      <Spinner message="Chargement d'autres pins..." />
    )}
    </>
  )
}

export default PinDetail;